import JSBI from "jsbi"

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  ZIRCUIT_MAINNET = 48900,
}
export enum RoundingMode {
  RoundDown = 0,
  RoundHalfUp = 1,
  RoundHalfEven = 2,
  RoundUp = 3,
}
export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const FACTORY_ADDRESS: { [key: string]: string } = {
  [ChainId.ZIRCUIT_MAINNET]: "0xd46324d57c1e1D143CD46CC3cDE295cD027633Dc",
}

export const INIT_CODE_HASH =
  "0x2f683740d2e319cde3e8564949394c4d4f24ac0c217c0bb02d62064611aa5afb"

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = "uint8",
  uint256 = "uint256",
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt("0xff"),
  [SolidityType.uint256]: JSBI.BigInt(
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
  ),
}
